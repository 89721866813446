import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import * as React from 'react';
import Box from '@mui/joy/Box';
import Alert from '@mui/joy/Alert';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import { ColorPaletteProp } from '@mui/joy/styles';

type AlertRegistrationProps = {
    title: string;
    text: string;
    color: ColorPaletteProp;
    icon: React.ReactElement;
};

export default function AlertRegistration(props: AlertRegistrationProps) {

    return (
        <Box sx={{ display: 'flex', gap: 2, width: '100%', flexDirection: 'column' }}>
            <Alert
                key={props.title}
                sx={{ alignItems: 'flex-start' }}
                startDecorator={props.icon}
                variant="soft"
                color={props.color}
                tabIndex={0}
                endDecorator={
                    <IconButton variant="soft" color={props.color}>
                        <CloseRoundedIcon />
                    </IconButton>
                }
            >
                <div>
                    <div>{props.title}</div>
                    <Typography level="body-sm" color={props.color}>
                        {props.text}
                    </Typography>
                </div>
            </Alert>
        </Box>
    );
}
