import * as React from 'react';
import ThemeRegistry from './components/ThemeRegistry/ThemeRegistry';
import '@fontsource/inter';

export const metadata = {
  title: 'UXAI',
  description: 'Less stress, more confidence with every new deployment.',
};

export default function RootLayout({ children }: { children: React.ReactNode }) {
  return (
    <ThemeRegistry>{children}</ThemeRegistry>
  );
}
