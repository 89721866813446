// import { NextResponse, NextRequest } from 'next/server';
import * as EmailValidator from 'email-validator';

const EXT_API_URL = process.env.EXTERNAL_API || "http://localhost:5008";

export async function HEAD(request: Request) {
    console.log(request, "HEAD");
    return new Response(null, {
        headers: {
            Allow: "GET, HEAD, OPTIONS",
        }
    })
}

export async function POST(request: Request) {
    console.log(request, "POST");

    var body = await request.json();

    const email = body.email;

    console.log(email, "email");

    if (email === null) {
        return new Response(JSON.stringify({ error: "Email is required" }), {
            status: 400,
            headers: {
                'Content-Type': 'application/json',
                'referrer-policy': 'origin-when-cross-origin',
            }
        })

    } else (EmailValidator.validate(email))
    {
        const response = await fetch(`${EXT_API_URL}/uxai/register-email`, {
            method: 'POST',
            body: email,
            headers: {
                'Content-Type': 'application/json',
                'referrer': 'nodejs-server',
            },
            mode: 'no-cors'
        });

        const body = await response;

        return new Response(JSON.stringify(body), { status: 200, headers: { 'Content-Type': 'application/json' } })
    }
}

export async function PUT(request: Request) {
    const body = await request.json()
    return new Response(JSON.stringify(body))
}

export async function DELETE(request: Request) {
    const body = await request.json()
    return new Response(JSON.stringify(body))
}

export async function PATCH(request: Request) {
    const body = await request.json()
    return new Response(JSON.stringify(body))
}

// If `OPTIONS` is not defined, Next.js will automatically implement `OPTIONS` and  set the appropriate Response `Allow` header depending on the other methods defined in the route handler.
export async function OPTIONS(request: Request) {
    return new Response(null, { headers: { Allow: "GET, HEAD, OPTIONS" } })
}