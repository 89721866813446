import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './App';
import RootLayout from './layout';
import '@fontsource/inter';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement!);

root.render(<RootLayout>
    <App />
</RootLayout>
);
