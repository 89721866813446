import * as React from 'react';
import Box from '@mui/joy/Box';
import Chip from '@mui/joy/Chip';
import Input from '@mui/joy/Input';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import TwoSidedLayout from '../components/TwoSidedLayout';
import { useColorScheme } from '@mui/joy';
import { POST } from '../api/route';
import FavoriteIcon from '@mui/icons-material/Favorite';
import AlertRegistration from '../components/AlertRegistration';
import ErrorIcon from '@mui/icons-material/Error';

const API_URL = "localhost:3000/api";

export default function LeftSide() {
  const { mode, systemMode } = useColorScheme();
  const [email, setEmail] = React.useState('');
  const [showAlert, setShowAlert] = React.useState("");

  const onFormAction = () => {
    console.log("Form Action");

    const request = new Request(API_URL + "/register-email", {
      method: 'POST',
      body: JSON.stringify({ "email": email }),
      headers: {
        'Content-Type': 'application/json',
        'referrer': 'client',
      },
    });

    POST(request).then((response) => {
      if (response?.status == 200) {
        console.log("Email Sign Up Success");
        setShowAlert("success");
      } else {
        console.log("Email Sign Up Failed");
        setShowAlert("failed");
      }
    });

  }

  return (
    <TwoSidedLayout reversed>
      <Chip size="lg" variant="outlined" color="primary">
        May the power of UXAI aid you in your QA
      </Chip>
      <Typography fontWeight="xl" >A product by <a href="https://www.ai-centraal.nl" target="_blank">AI-Centraal</a></Typography>
      <Typography
        level="h1"
        fontWeight="xl"
        fontSize="clamp(1.875rem, 1.3636rem + 2.1818vw, 3rem)"
      >
        <Typography color={systemMode == 'light' || mode == 'light' ? 'primary' : 'primary'} variant="outlined">UXAI</Typography> Less stress, more confidence with every new deployment.
      </Typography>
      <Typography fontSize="lg" textColor="text.secondary" lineHeight="lg">
        Let UXAI aid you in making sure your UI and functionality stay consistent at all times. Through every new deployment.
      </Typography>
      <Box
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          onFormAction();
        }}
        sx={{
          display: 'flex',
          gap: 1,
          my: 2,
          alignSelf: 'stretch',
          flexBasis: '80%',
        }}
      >
        <Input
          required
          name="email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          size="lg"
          placeholder="Come back soon to sign up and get notified!"
          // placeholder="Come back soon to sign up and get notified of exciting updates!"
          // placeholder="Sign up to get notified of exciting updates!"
          disabled
          sx={{ flex: 'auto' }}
        />
        <IconButton type="submit" size="lg" variant="solid" disabled color="primary">
          <MarkEmailReadIcon />
        </IconButton>
      </Box>
      <Typography
        level="body-xs"
        sx={{
          position: 'absolute',
          top: '2rem',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      >
      </Typography>
      {
        showAlert == "success" ? <AlertRegistration color='primary' title="Thank you for signing up!" text="We have sent an email to confirm your sign up for exciting UXAI updates." icon={<FavoriteIcon />} />
          :
          showAlert == "failed" ? <AlertRegistration color='warning' title="Oops! Something went wrong." text="Unfortunately, something went wrong. Please try again later." icon={<ErrorIcon />} />
            :
            <></>
      }
    </TwoSidedLayout >
  );
}
